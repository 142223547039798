import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormStep = lazy(
  () => import("./form-step-Bixudo3I.js").then((module) => ({
    default: module.FormStep
  }))
);
function FormStep({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormStep, { ...props });
}
const useFormStepContent = () => {
  return useMemo(
    () => ({
      formStep: {
        component: FormStep
      }
    }),
    []
  );
};
export {
  LazyFormStep as FormStep,
  useFormStepContent
};
